import APIClient from "@/services/APIClient";
import PaymentProviderConfig from "@/apiModels/PaymentModels/PaymentProviderConfig.ts";
import { useQuery } from "@tanstack/react-query";
import BaseServices from "@/services/BaseServices";

interface Props {
  eventId?: string;
}

const usePaymentProviderConfig = ({ eventId }: Props) => {
  const apiClient = new APIClient<PaymentProviderConfig>(
    BaseServices.SEAT_RESERVATION_SERVICE,
    `/user-app/event/${eventId}/list-payment-method`
  );

  return useQuery<PaymentProviderConfig[], Error>({
    queryKey: ["paymentProviderConfig", eventId],
    queryFn: () => apiClient.getList(),
    enabled: !!eventId
  });
};

export default usePaymentProviderConfig;
