enum EventDetailStatus {
  SOLD_OUT = "sold_out",
  COMPLETED = "completed",
  CLOSED = "closed",
  OPENED = "opened"
}

interface EventDetailAPIModel {
  id: string;
  name: string;
  date: string;
  venue: Venue;
  organizer: string;
  venue_map: VenueMap;
  status: EventDetailStatus;
}

interface VenueMap {
  id: string;
  areas: VenueArea[];
}

interface VenueArea {
  id: string;
  name: string;
  is_default: boolean;
}

interface Venue {
  id: string;
  name: string;
  address: string;
  image: string;
}

export default EventDetailAPIModel;

export { EventDetailStatus };

export type { VenueMap, VenueArea, Venue };
