import React, { useEffect, useMemo, useRef, useState } from "react";
import SeatSelector from "@/components/ui/SeatSelector";
import Container from "@/components/ui/Container";
import SectionTitle from "@/components/ui/SectionTitle";
import PaymentMethodEntry, {
  PaymentMethodRef
} from "@/components/ui/PaymentMethodEntry";
import ForMoreInquiry from "@/components/ui/ForMoreInquiry";
import PersonalDetailEntry from "@/components/ui/PersonalDetailEntry";
import BottomBar from "@/components/ui/BottomBar";
import useBulkTableCartStore from "@/stores/BulkTableCartStore.ts";
import emailValidator from "@/utils/emailValidator.ts";
import { toast } from "react-toastify";
import useBulkCartSeat from "@/hooks/useBulkCartSeat.ts";
import { StyledQRSeatBookingPageContent } from "@/views/QRView/QRSeatBookingPage/QRSeatBookingPage.styled.ts";
import RevelQrLoading from "@/components/ui/RevelQrLoading";
import useSeats from "@/hooks/useSeats.ts";
import { useParams } from "react-router-dom";
import useReservationSchedule from "@/hooks/useEvent.ts";
import venueMaps from "@/data/venueMapData";
import ReservationSummary from "@/components/ui/ReservationSummary";
import ReservationDateSelector from "@/components/ui/ReservationDateSelector";
import AreaSelector from "@/components/ui/AreaSelector";
import BusinessBrandBar from "@/components/ui/BusinessBrandBar";
import {
  EventDetailStatus,
  VenueArea
} from "@/apiModels/ReservationScheduleAPIModel";

const QRSeatBookingPage: React.FunctionComponent = () => {
  const { id: qrCode } = useParams();

  const paymentRef = useRef<PaymentMethodRef>(null);
  const [bookingId, setBookingId] = useState<string>("");
  const [businessId, setBusinessId] = useState<string>("");
  const [isMutatingCart, setIsMutatingCart] = useState<boolean>(false);
  const [emptyFullNameError, setEmptyFullNameError] = useState("");
  const [emptyEmailError, setEmptyEmailError] = useState("");
  const [invalidEmailTypeError, setInvalidEmailTypeError] = useState("");
  const [emptyPhoneNumberError, setEmptyPhoneNumberError] = useState("");
  const [emptyReservationsError, setEmptyReservationsError] = useState("");
  const [normalUser, setNormalUser] = useState<string>("");

  const scrollToFormRef = useRef<HTMLDivElement>(null);
  const scrollToSeatSelectorRef = useRef<HTMLDivElement>(null);

  const {
    data: event,
    isLoading: isReservationScheduleLoading,
    error: reservationScheduleError
  } = useReservationSchedule({ scheduleQrCode: qrCode });

  const [selectedArea, setSelectedArea] = useState<VenueArea>(() => {
    const defaultArea = event?.venue_map.areas.find((area) => area.is_default);

    if (!defaultArea) {
      return {
        id: "",
        name: "",
        is_default: false
      };
    }

    return defaultArea;
  });

  const { data, isLoading, error } = useSeats({
    eventId: event?.id,
    areaId: selectedArea.id
  });

  useEffect(() => {
    if (event) {
      const defaultArea = event.venue_map?.areas.find(
        (area) => area.is_default
      );

      if (defaultArea) {
        setSelectedArea(defaultArea);
      }
    }
  }, [event]);

  const getVenueMap = useMemo(() => {
    if (!isReservationScheduleLoading && !reservationScheduleError) {
      setBusinessId(event!.organizer);

      const filteredMapArea = venueMaps
        .flatMap((venueMap) => venueMap.areas)
        .find((area) => area.id === selectedArea.id);

      if (filteredMapArea) {
        return filteredMapArea;
      }
    }
  }, [
    selectedArea,
    isReservationScheduleLoading,
    reservationScheduleError,
    setBusinessId,
    event
  ]);

  const {
    fullName,
    email,
    phoneNumber,
    setFullName,
    setEmail,
    setPhone,
    reservations,
    totalAmount
  } = useBulkTableCartStore((store) => ({
    fullName: store.full_name,
    setFullName: store.setFullName,
    email: store.email,
    setEmail: store.setEmail,
    phoneNumber: store.phone_number,
    setPhone: store.setPhoneNumber,
    reservations: store.reservationTables,
    totalAmount: store.totalAmount,
    setTotalAmount: store.setTotalAmount
  }));

  const addToBulkCart = useBulkCartSeat({
    cbError: (error) => {
      error!.response.data.errors.map((e) => {
        toast.error(e.message[0]);
      });
      setIsMutatingCart(false);
    },
    cb: () => {
      setIsMutatingCart(true);
    },
    cbSuccess: (data) => {
      if (data) {
        toast.success("Seat added to cart");
        setBookingId(data.id);
        setNormalUser(data.normal_user);

        localStorage.setItem("normalUser", data.normal_user);

        if (typeof qrCode === "string") {
          localStorage.setItem("qrCode", qrCode);
        }
      }
    }
  });

  useEffect(() => {
    // For persisting reservation across area
    localStorage.removeItem("switch");
  }, []);

  useEffect(() => {
    if (bookingId) {
      localStorage.setItem("businessId", businessId);
      localStorage.setItem("bookingId", bookingId);
      paymentRef?.current!.initPayment();
    }
  }, [businessId, bookingId]);

  useEffect(() => {
    if (reservations.length !== 0) {
      setEmptyReservationsError("");
    }
    if (fullName) {
      setEmptyFullNameError("");
    }
    if (email) {
      setEmptyEmailError("");
    }
    if (phoneNumber) {
      setEmptyPhoneNumberError("");
    }
  }, [reservations.length, fullName, email, phoneNumber]);

  const showFieldEmptyToastAndScroll = () => {
    toast.error("Please fill all the required fields");
    scrollToFormRef?.current?.scrollIntoView({
      behavior: "smooth"
    });
  };

  const handleBulkSubmit = () => {
    const { isValidEmail } = emailValidator(email);
    setEmptyReservationsError("");
    setEmptyFullNameError("");
    setEmptyPhoneNumberError("");
    setEmptyEmailError("");
    setInvalidEmailTypeError("");

    if (reservations.length === 0) {
      toast.error("Please select the seat");
      scrollToSeatSelectorRef?.current?.scrollIntoView({
        behavior: "smooth"
      });
      setEmptyReservationsError("Select at least one seat");
      return;
    }

    if (!fullName) {
      showFieldEmptyToastAndScroll();
      setEmptyFullNameError("Full name is required");
      return;
    }

    if (!phoneNumber) {
      showFieldEmptyToastAndScroll();
      setEmptyPhoneNumberError("Phone number is required");
      return;
    }

    if (!email) {
      showFieldEmptyToastAndScroll();
      setEmptyEmailError("Email is required");
      return;
    }

    if (!isValidEmail) {
      setInvalidEmailTypeError("Invalid email type");
      return;
    }

    if (!localStorage.getItem("paymentMethod")) {
      toast.error("No payment method selected");
      return;
    }

    addToBulkCart.mutate({
      eventId: event!.id!,
      payload: {
        email: email,
        fullname: fullName,
        medium: "web",
        phone_number: String(phoneNumber),
        seats: reservations.map((res) => res.id),
        checkin_time: new Date().toLocaleTimeString("np", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit"
        })
      }
    });
  };

  if (isLoading || isReservationScheduleLoading || !selectedArea) {
    return <RevelQrLoading />;
  }

  if (error || reservationScheduleError || !event) {
    throw new Error();
  }

  return (
    <React.Fragment>
      <StyledQRSeatBookingPageContent
        $isDisabled={
          isMutatingCart || event.status !== EventDetailStatus.OPENED
        }
      >
        <Container>
          {/*<StyledQRSeatBookingPageHead>*/}
          {/*  {reservationSchedule!.date} - {}.*/}
          {/*</StyledQRSeatBookingPageHead>*/}

          {/*<BusinessBrandBar*/}
          {/*  eventName={reservationSchedule!.name}*/}
          {/*  brandIcon={reservationSchedule.venue_image}*/}
          {/*  brandName={reservationSchedule.venue_name}*/}
          {/*  brandAddress={reservationSchedule.location}*/}
          {/*  brandSocialLinks={reservationSchedule.venue_socials}*/}
          {/*/>*/}

          <div ref={scrollToSeatSelectorRef}></div>

          <AreaSelector
            selectedArea={selectedArea}
            areas={event.venue_map.areas}
            setSelectedArea={setSelectedArea}
          />

          {getVenueMap && (
            <SeatSelector
              errorText={emptyReservationsError}
              data={data}
              venueMap={getVenueMap}
            />
          )}

          <BusinessBrandBar
            eventName={event!.name}
            brandIcon={event.venue.image}
            brandName={event.venue.name}
            brandAddress={event.venue.address}
          />
          <ReservationDateSelector reservationSchedule={event!} />
          {reservations.length > 0 && (
            <>
              <div ref={scrollToFormRef}></div>
              <ReservationSummary reservationSchedule={event!} />
              <SectionTitle>Enter Personal Details</SectionTitle>
              <PersonalDetailEntry
                fullName={fullName}
                email={email}
                phone_number={phoneNumber}
                setFullName={setFullName}
                setEmail={setEmail}
                setPhone={setPhone}
                emptyFullNameError={emptyFullNameError}
                emptyEmailError={emptyEmailError}
                emptyPhoneNumberError={emptyPhoneNumberError}
                invalidEmailTypeError={invalidEmailTypeError}
              />
              <SectionTitle>Choose Payment Method</SectionTitle>
              <PaymentMethodEntry
                ref={paymentRef}
                eventDetailId={event!.id}
                businessExternalId={event!.organizer}
                reservationId={bookingId}
                normalUser={normalUser}
              />
            </>
          )}
          <ForMoreInquiry />
        </Container>
      </StyledQRSeatBookingPageContent>

      <BottomBar
        isSeatSelected={reservations.length > 0}
        buttonLabel={
          event.status === EventDetailStatus.CLOSED
            ? "Closed"
            : event.status === EventDetailStatus.SOLD_OUT
              ? "Sold Out"
              : event.status === EventDetailStatus.COMPLETED
                ? "Completed"
                : reservations.length > 0
                  ? "Reserve"
                  : "Select Table"
        }
        isDisabled={isMutatingCart || event.status !== EventDetailStatus.OPENED}
        isLoading={isMutatingCart}
        onClick={handleBulkSubmit}
        price={totalAmount}
        totalReservation={reservations.length}
      />
    </React.Fragment>
  );
};

export default QRSeatBookingPage;
